function paralaxImages(scrollElme) {
    'use strict';
    const scrollRect = scrollElme.getBoundingClientRect();
    const scrollRectY = window.scrollY + scrollRect.top;

    const scrollHeight = Math.abs(scrollRect.top - scrollRect.bottom);
    const scrollHeight1per = scrollHeight / 100;

    const scrollHeight10per = scrollHeight / 10;
    function fade(scrollChild) {
        const childRect = scrollChild.getBoundingClientRect();
        if ((window.scrollY + childRect.top) <= ((window.scrollY + scrollRect.top))) {
            return (scrollRectY - (window.scrollY + childRect.top)) / 100;
        }
        // scrollRect.bottom + scrollHeight10per;
    }
    document.querySelectorAll(".usp-detailed-info__image-holder").forEach(function (elem) {
        translateImage(elem, scrollElme);
    });
}

function translateImage(image, scrollElme) {
    'use strict';
    function clamp(min, num, max) { return Math.min(Math.max(num, min), max); }

    const parentRect = image.parentElement.getBoundingClientRect();
    const scrollRect = scrollElme.getBoundingClientRect();

    const scrollHeight = Math.abs(scrollRect.top - scrollRect.bottom);
    const scrollHeight1per = scrollHeight / 100;

    const transMin = image.hasAttribute("data-transition-min") ?
        Number.parseInt(image.getAttribute("data-transition-min")) : -70;
    const transMax = image.hasAttribute("data-transition-max") ?
        Number.parseInt(image.getAttribute("data-transition-max")) : 70;

    function translatePer() { return -(((parentRect.top + (parentRect.height / 2)) / scrollHeight1per) - 50); }
    function translatePx() { return (parentRect.height / 100) * clamp(transMin, translatePer(), transMax); }

    image.style.transform = "skewY(3deg) translateY(" + clamp(transMin, translatePer(), transMax) + "%)";
}

function eventListenerThrottle(elemEvent, callable, timeout) {
    'use strict';
    let ticking = false;
    elemEvent.addEventListener('scroll', function (e) {
        if (!ticking) {
            window.requestAnimationFrame(function () {
                callable(e.target);
                ticking = false;
            });
            ticking = true;
        }
    });
}

function centreElement(element) {
    'use strict';

    const scrollRect = element.getBoundingClientRect();
    if ((scrollRect.top >= 0 && scrollRect.top <= window.innerHeight) ||
        (scrollRect.bottom >= 0 && scrollRect.bottom <= window.innerHeight)) {
        try {
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
        catch (error) {
            element.scrollIntoView(true);
        }
    }
}

function checkIfLeftScrollArea() {
    'use strict';

    setTimeout(function () { document.getElementById("usp-banner__scroll").addEventListener("scroll", centreElementEvents); }, 1000);
    window.removeEventListener("scroll", checkIfLeftScrollArea);
}

function centreElementEvents() {
    'use strict';
    centreElement(document.getElementById("usp-banner__scroll"));

    document.getElementById("usp-banner__scroll").removeEventListener("scroll", centreElementEvents);
    window.addEventListener("scroll", checkIfLeftScrollArea);
}

//document.getElementById("usp-banner__scroll").addEventListener("scroll", centreElementEvents);

eventListenerThrottle(document.getElementById("usp-banner__scroll"), paralaxImages);
eventListenerThrottle(window, function () { paralaxImages(document.getElementById("usp-banner__scroll")); });

paralaxImages(document.getElementById("usp-banner__scroll"));